import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import { getImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from '@bootstrap-styled/v4'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Footer from '../components/Footer'
import Section from '../components/UI/Section'
import GoogleReviewsBlock from '../components/Sections/GoogleReviewsBlock'
import PageHeader from '../components/PageHeader'
import ContactMePersonal from '../components/ContactMePersonal'
import SectionHeading from '../components/SectionHeading'
import ServicesBlock from '../components/Sections/ServicesBlock'
import HeroImageHeader from '../components/HeroImageHeader'
import { P, H2, H4 } from '../components/Typography'
import breakpoints from '../util/style/breakpoints'
import Card from '../components/UI/Card'

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}

  .filter-spacing {
    margin-bottom: 2.5rem;
  }
`

const CardWithCol = styled(Card)`
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;

  ${breakpoints.md`
    padding: 3.5rem;
  `}

  ${breakpoints.lg`
      padding: 6.5rem;
  `}

  .left-col {
    width: 100%;

    ${breakpoints.md`
        padding-right: 6.5rem;
        width: 50%;
    `}
  }

  .right-col {
    width: 100%;

    ${breakpoints.md`
        width: 50%;
  `}
  }
`

export const servicesQuery = graphql`
  query {
    imageRealEstateAgents: file(
      relativePath: { eq: "diensten/makelaars.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    makelaarsSummaryJson {
      en {
        title
        description
      }
      nl {
        title
        description
      }
    }
    makelaarsBodyJson {
      en {
        textBlock1Title
        textBlock1
        textBlock2Title
        textBlock2
        textBlock3
        textBlock4Title
        textBlock4
        textBlock5
        textBlock6Title
        textBlock6
        textBlock7
      }
      nl {
        textBlock1Title
        textBlock1
        textBlock2Title
        textBlock2
        textBlock3
        textBlock4Title
        textBlock4
        textBlock5
        textBlock6Title
        textBlock6
        textBlock7
      }
    }
  }
`

class ServicesLandLordsPage extends React.PureComponent {
  render() {
    const { intl, data } = this.props
    const imgHeader = getImage(data.imageRealEstateAgents)
    const { title, description } = data.makelaarsSummaryJson[intl.locale]
    const {
      textBlock1Title,
      textBlock1,
      textBlock2Title,
      textBlock2,
      textBlock3,
      textBlock4Title,
      textBlock4,
      textBlock5,
      textBlock6Title,
      textBlock6,
      textBlock7,
    } = data.makelaarsBodyJson[intl.locale]

    return (
      <>
        <Layout>
          <SEO title={`${intl.formatMessage({ id: 'site_title' })} - ${title}`} />
          <TemplateWrapper>
            <PageHeader
              subTitle={intl.formatMessage({ id: 'REAL_ESTATE_AGENTS' })}
              title={title}
              description={description}
            />
            {imgHeader && (
              <Section>
                <Container>
                  <HeroImageHeader imgHeader={imgHeader} />
                </Container>
              </Section>
            )}

            <Section>
              <Container>
                <Row className="mb-3 text-md-center">
                  <Col md={12}>
                    <H2>{textBlock1Title}</H2>
                  </Col>
                </Row>
                <Row className="mb-3 text-md-center">
                  <Col md={12}>
                    <P>{textBlock1}</P>
                  </Col>
                </Row>
                <Row className="mb-3 mb-md-5">
                  <Col md={12}>
                    <CardWithCol>
                      <div className="left-col">
                        <H4 color="#b98752" className="mb-3">
                          {textBlock2Title}
                        </H4>
                        <P>{textBlock2}</P>
                      </div>
                      <div className="right-col">
                        <ul className="check-mark-list">
                          <span
                            dangerouslySetInnerHTML={{ __html: textBlock3 }}
                          />
                        </ul>
                      </div>
                    </CardWithCol>
                  </Col>
                </Row>
                <Row className="mb-3 mb-md-5">
                  <Col md={12}>
                    <CardWithCol>
                      <div className="left-col">
                        <H4 color="#b98752" className="mb-3">
                          {textBlock4Title}
                        </H4>
                        <P>{textBlock4}</P>
                      </div>
                      <div className="right-col">
                        <ul className="check-mark-list">
                          <span
                            dangerouslySetInnerHTML={{ __html: textBlock5 }}
                          />
                        </ul>
                      </div>
                    </CardWithCol>
                  </Col>
                </Row>
                <Row className="mb-3 mb-md-5">
                  <Col md={12}>
                    <CardWithCol>
                      <div className="left-col">
                        <H4 color="#b98752" className="mb-3">
                          {textBlock6Title}
                        </H4>
                        <P>{textBlock6}</P>
                      </div>
                      <div className="right-col">
                        <ul className="check-mark-list">
                          <span
                            dangerouslySetInnerHTML={{ __html: textBlock7 }}
                          />
                        </ul>
                      </div>
                    </CardWithCol>
                  </Col>
                </Row>
              </Container>
            </Section>
            <Section>
              <Container>
                <GoogleReviewsBlock />
              </Container>
            </Section>
            <Section>
              <Container>
                <SectionHeading
                  header={intl.formatMessage({ id: 'POSSIBLY_INTERESTING' })}
                  subTitle={intl.formatMessage({ id: 'INTERESTING_SERVICES' })}
                />
                <ServicesBlock landlords renters />
              </Container>
            </Section>
            <ContactMePersonal
              header={intl.formatMessage({ id: 'contact.title' })}
              subTitle={intl.formatMessage({
                id: 'contact.SUB_TITLE_AMBER',
              })}
              contactUs={intl.formatMessage({
                id: 'CONTACT_US',
              })}
            />
            <Footer />
          </TemplateWrapper>
        </Layout>
      </>
    )
  }
}

export default injectIntl(ServicesLandLordsPage)
